import { Form } from 'element-ui/types/element-ui';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { Message } from 'element-ui';
import { departmentService } from '@/api';
import { messageError, translation } from '@/utils';

@Component({
  name: 'bind-process'
})
export default class BindProcess extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;

  @Prop({ required: false, type: Number, default: 0 })
  public id!: number;

  public title = 'button.bindProcess';
  public departmentForm: {
    id: number;
    processType: number | String | null;
  } = {
    id: 0,
    processType: ''
  };

  public departmentFormRules = {
    processType: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('department.selectProcess')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };
  public submitLoading = false;
  public processTypeOptions: Array<any> = [];

  public created(): void {
    this.processTypeOptions = departmentService.getBindProcessSelectableList();
  }
  public dialogOpen(): void {
    this.departmentForm.id = this.id;
    // 获取当前部门绑定的岗位
    departmentService.getProcessTypeId(this.id).then(res => {
      this.departmentForm.processType = res.processType;
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.departmentForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.departmentForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (!this.departmentForm.processType) {
          this.departmentForm.processType = null;
        }

        await departmentService.bindProcess({ ...this.departmentForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.departmentForm);
        this.closeDialog();
      } catch (error) {
        messageError(error as any);
      } finally {
        this.setLoading(false);
      }
    });
  }

  private setLoading(value: boolean): void {
    this.submitLoading = value;
  }

  private closeDialog(): void {
    this.syncedVisible = false;
  }
}
