import { ResourceStatusEnum } from '@/resource/enum';
import { DepartmentResource } from '@/resource/model';
import { CascaderOption } from 'element-ui/types/cascader-panel';
import { Form } from 'element-ui/types/element-ui';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { Message } from 'element-ui';
import { departmentService } from '@/api';
import { messageError, translation } from '@/utils';

@Component({
  name: 'add-department'
})
export default class AddDepartment extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public departmentItem!: DepartmentResource | null;
  @Prop({ required: false, type: Number, default: null }) public parentId!: number | null;

  public title = 'department.addDepartment';
  public departmentForm: {
    parentId: number;
    depName: string;
    status: ResourceStatusEnum;
    sort: number;
  } = {
    parentId: 0,
    depName: '',
    status: ResourceStatusEnum.using,
    sort: 1
  };

  public departmentFormRules = {
    depName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('department.nameEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (value.includes(' ')) {
            callback(new Error(translation('department.nameSpace')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          departmentService
            .checkDepartmentName(value, this.departmentItem?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.departmentNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    sort: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('department.sortEmpty')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };
  public submitLoading = false;
  public treeOption: Array<CascaderOption> = [];
  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.parentId) {
      this.departmentForm.parentId = this.parentId;
    }
    this.getParentDepartments().then(() => {
      if (this.departmentItem) {
        // 如果是修改，需要把要修改的部门自身，从部门列表中过滤出去
        this.treeOption = this.filterSelfDepartment(this.treeOption);
      }
    });
    if (!this.departmentItem) {
      this.operationType = 'add';
      this.title = 'department.addDepartment';
      return;
    }
    this.operationType = 'edit';
    this.title = 'department.editDepartment';
    this.$nextTick(() => {
      Object.assign(this.departmentForm, this.departmentItem);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.departmentForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.departmentForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await departmentService.post({ ...this.departmentForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await departmentService.put({ ...this.departmentForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.departmentForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  private async getParentDepartments(): Promise<void> {
    try {
      const departments = await departmentService.getAllDepartments();
      this.treeOption = this.generateSelectOption(departments);
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 递归生成表单中,上级部门需要的数据
   * @param department 部门数据
   * @returns 树状select需要的数据格式
   */
  private generateSelectOption(departments: Array<DepartmentResource>): Array<CascaderOption> {
    return departments.map(x => {
      const option: CascaderOption = {
        label: x.depName,
        value: x.id
      };
      if (x.children && x.children.length > 0) {
        option.children = this.generateSelectOption(x.children);
      }
      return option;
    });
  }

  /**
   * 从用于选择的部门级联选择选项中，过滤掉要修改的部门本身
   * @param treeOption 部门树状数据
   * @returns
   */
  private filterSelfDepartment(treeOption: Array<CascaderOption>): Array<CascaderOption> {
    return treeOption.filter(x => {
      if (x.children && x.children.length > 0) {
        x.children = this.filterSelfDepartment(x.children);
      }
      return x.value !== this.departmentItem?.id;
    });
  }

  private setLoading(value: boolean): void {
    this.submitLoading = value;
  }

  private closeDialog(): void {
    this.syncedVisible = false;
  }
}
